<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close"
    @keydown.esc="close"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar dark class="mb-2">
          <v-btn icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-btn>
          <v-toolbar-title>Typ: {{ type.description }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            color="error"
            @click="edit"
            fab
            small
            class="mr-4"
            v-if="!type.archived"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-4">
        <RowItem align="center" title="Titel">
          <h3>{{ type.title }}</h3>
        </RowItem>
        <RowItem align="center" title="erstellt von">
          <PersonItem :value="type.creator" />
        </RowItem>
        <RowItem title="Text">
          <Markdown :value="type.text" />
        </RowItem>
        <RowItem title="Abteilung">
          <LookupValue :value="type.division" />
        </RowItem>
        <RowItem title="Stufe">
          <LookupValue :value="type.grade" />
        </RowItem>
        <RowItem title="Semester">
          <LookupValue :value="type.semester" />
        </RowItem>
        <RowItem title="Fach">
          <LookupValue long :value="type.subject" />
        </RowItem>
        <RowItem title="automatisch">
          <v-simple-checkbox disabled :value="type.autogenerate" />
        </RowItem>
        <RowItem title="versteckt">
          <v-simple-checkbox disabled :value="type.hidden" />
        </RowItem>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer /><v-btn text @click="close">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LookupValue from "common/components/LookupValue.vue";
import Markdown from "@/components/Markdown.vue";
import PersonItem from "@/components/PersonItem.vue";
import RowItem from "@/components/RowItem.vue";

export default {
  name: "PortfolioDetail",
  props: ["id"],
  components: { LookupValue, Markdown, PersonItem, RowItem },
  data() {
    return { type: {}, loading: false };
  },
  methods: {
    edit() {
      this.$router.push({
        name: "PortfolioTypeEdit",
        params: { id: this.type.id },
      });
    },
    close() {
      this.$router.push({
        name: "PortfolioTypeList",
      });
    },
  },
  async created() {
    this.loading = true;
    this.type = await this.apiGet({ resource: "portfolio/type", id: this.id });
    this.loading = false;
  },
};
</script>
